@font-face {
  font-family: 'UberMove-Medium';
  font-display: swap;
  src: url('./font/UberMove-Medium.woff2') format('woff2'),
  url('./font/UberMove-Medium.woff') format('woff');
}
@font-face {
  font-family: 'UberMoveText-Medium';
  font-display: swap;
  src: url('./font/UberMoveText-Medium.woff2') format('woff2'),
  url('./font/UberMoveText-Medium.woff') format('woff');
}
@font-face {
  font-family: 'UberMove-Bold';
  font-display: swap;
  src: url('./font/UberMove-Bold.woff2') format('woff2'),
  url('./font/UberMove-Bold.woff') format('woff');
}
@font-face {
  font-family: 'UberMoveText-Regular';
  font-display: fallback;
  src: url('./font/UberMoveText-Regular.woff2') format('woff2'),
  url('./font/UberMoveText-Regular.woff') format('woff');
}