    .centered-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200vh; 
    }
    
    .form-container {
      display: flex;
      justify-content: center; 
      align-items: center; 
      height: 100vh; 
    }
    
    .form {
      display: flex;
      flex-direction: column;
      /* align-items: center; */
      width: 100%;
      max-width: 600px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #fff;
      margin: 100px auto;
      margin-top: 10px; 
    }
    
    @media screen and (max-width: 768px) {
      .form {
        width: 90%; 
        margin: 100px auto; 
      }
    }
    
    .form h1 {
      text-align: center;
    }
    
    .form-row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 5px;
    }
    
    .form-group {
      display: flex;
      flex-direction: column;
      width: 48%;
    }
    
    .form label {
      margin: 10px 0 5px;
    }
    
    .form input,
    .form textarea {
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
    
    .form textarea {
      height: 100px;
      resize: vertical; 
      overflow: auto; 
    }
    
    .form button {
      width: 50%;
      padding: 10px;
      color: #fff;
      border: none;
      border-radius: 4px;
      background-color: rgb(2, 2, 110);
      cursor: pointer;
      margin-left: 25%;
    }
    
    .form button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
    