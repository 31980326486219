.form {
    border: 2px solid #0e3692;
  }
  
  .form input,
  .form textarea {
    border: 2px solid #0e3692;
    padding: 10px;
    border-radius: 5px;
    outline: none;
  }
  
  .form input:focus,
  .form textarea:focus {
    border-color: #4297c8;
  }
  
  .form button {
    background-color: rgb(2, 2, 110);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .form button:active {
    background-color: #4297c8;
  }