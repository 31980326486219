/* animation*/
.reveal_animation {
  transition: opacity 1s ease-in-out, transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
  transition-delay: 0s, 0s, 0s;
  opacity: 0;
  -webkit-transform: scale(0) translateY(30px);
  transform: scale(0.9) translateY(30px);
}
.reveal_animation.enter {
  transition: all 1s 0.25s ease-in-out;
  opacity: 1;
  -webkit-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}

/* animation*/

/* Push animation */
.push_animation {
  transition: opacity 1s ease-in-out, transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
  transition-delay: 0s, 0s, 0s;
  opacity: 0;
  transform: translateY(50px); /* Starting slightly off-screen or below its final position */
  -webkit-transform: translateY(100px);
}

.push_animation.enter {
  transition: all 1s 0.25s ease-in-out;
  opacity: 1;
  transform: translateY(0); /* Move to final position */
  -webkit-transform: translateY(0);
}

.slide_animation {
  opacity: 0;
  transform: translateX(-50px);
  transition: all 0.6s ease-out;
}

.slide_animation.enter {
  opacity: 1;
  transform: translateX(0);
}


.slide_in_animation {
  opacity: 0;
  transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.8s ease-out;
  will-change: opacity, transform;
}

/* Slide from the left */
.slide_in_animation.left {
  transform: translateX(-100px);
}

/* Slide from the right */
.slide_in_animation.right {
  transform: translateX(100px);
}

/* Slide from the top */
.slide_in_animation.top {
  transform: translateY(-100px);
}

/* Slide from the bottom */
.slide_in_animation.bottom {
  transform: translateY(100px);
}

/* Visible state */
.slide_in_animation.left.enter,
.slide_in_animation.right.enter,
.slide_in_animation.top.enter,
.slide_in_animation.bottom.enter {
  opacity: 1;
  transform: translateX(0);
}
